import "@ionic/vue/css/ionic.bundle.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

import "@/css/ionic.colors.css";
import "@/css/ionic.extensions.css";
import "@/css/style.css";

import router from "./router";
import store from "./store";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { IonicVue } from "@ionic/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { createI18n } from "vue-i18n";
import { getDeviceLanguage } from "@/lib/locale";
import i18nDirective from "@/directives/i18n";

import App from "./App.vue";
import BasePage from "@/components/BasePage.vue";
import "./registerServiceWorker";

import { addIcons } from "ionicons";
import * as icons from "ionicons/icons";

import en from "seedgreen-shared/lang/en.json";
import es from "seedgreen-shared/lang/es.json";

function camelCaseToDash(str: string) {
	return str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
}

// Load all ionic icons
addIcons(
	Object.entries(icons).reduce((acc: { [key: string]: string }, entry) => {
		const name = camelCaseToDash(entry[0]); // Convert to dash case

		acc[`ios-${name}`] = entry[1];
		acc[`md-${name}`] = entry[1];

		return acc;
	}, {}),
);

// I18n localization plugin setup
export const i18n = createI18n({
	allowComposition: true, // Note: this option will be removed in i18n v10
	locale: store.state.locale || getDeviceLanguage(),
	fallbackLocale: "en",
	messages: { en, es },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)
	.use(IonicVue)
	.use(pinia)
	.use(store)
	.use(router)
	.use(i18n)
	.directive("i18n", i18nDirective)
	.component("base-page", BasePage);

router.isReady().then(() => {
	app.mount("#app");
});
