import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_app = _resolveComponent("ion-app")!
  const _directive_i18n = _resolveDirective("i18n")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_router_outlet),
      (_ctx.showSideMenu)
        ? (_openBlock(), _createBlock(_component_ion_menu, {
            key: 0,
            side: "end",
            type: "overlay",
            ref: "menu",
            "content-id": "menu-content",
            "swipe-gesture": "false"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, {
                    color: _ctx.online ? 'branding' : 'dark'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, { style: {"padding":"0px 40px"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.personicon }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.user.name), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["color"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { id: "menu-content" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, {
                    style: {"pointer-events":"auto"},
                    onClick: _ctx.closeMenu
                  }, {
                    default: _withCtx(() => [
                      (_ctx.permissions['edit_any_field_note'] || _ctx.permissions['create_field_notes'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            button: "",
                            onClick: _ctx.showNotePopup
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.createicon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("note.create-note")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.permissions['create_task_request'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 1,
                            button: "",
                            onClick: _ctx.showTaskRequestPopup
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.clipboardicon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("task.task-request")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        button: "",
                        disable: _ctx.$router.currentRoute.value.name?.toString().startsWith('tasks.'),
                        color: _ctx.$router.currentRoute.value.name?.toString().startsWith('tasks.') ? 'primary' : '',
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'tasks.list' })))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.checkboxicon,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("task.task", 2)), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.tasksDue.length)
                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                key: 0,
                                color: "danger",
                                style: {"padding":"4px 6px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.tasksDue.length), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["disable", "color"]),
                      (_ctx.permissions['view_mobile_harvest_overview'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 2,
                            button: "",
                            color: _ctx.$router.currentRoute.value.name === 'harvest-overview' ? 'primary' : '',
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'harvest-overview' })))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.leaficon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("harvest-overview.harvest-overview")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (_ctx.permissions['view_mobile_harvest'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 3,
                            button: "",
                            color: _ctx.$router.currentRoute.value.name === 'harvest-schedule' ? 'primary' : '',
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'harvest-schedule' })))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.calendaricon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("harvest-schedule.harvest-schedule")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (_ctx.permissions['view_mobile_lots'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 4,
                            button: "",
                            color: _ctx.$router.currentRoute.value.name === 'lot-search' ? 'primary' : '',
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'lot-search' })))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.searchicon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("lot-search.lot-search")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (_ctx.permissions['view_irrigation_schedules'] || _ctx.permissions['set_irrigation_schedules'])
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 5,
                            button: "",
                            color: _ctx.$router.currentRoute.value.name === 'irrigation' ? 'primary' : '',
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'irrigation' })))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.watericon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("task.irrigation")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        button: "",
                        color: _ctx.$router.currentRoute.value.name === 'alerts' ? 'primary' : '',
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({ name: 'alerts' })))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.notificationsicon,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tc("common.alert", 2)), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.alerts.length)
                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                key: 0,
                                color: "danger",
                                style: {"padding":"4px 6px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.alerts.length), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      (!_ctx.deviceRegistered)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 6,
                            button: "",
                            onClick: _ctx.registerDevice
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.fingerprinticon,
                                slot: "start"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("menu.register-device")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        button: "",
                        onClick: _ctx.logout
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.logouticon,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("menu.logout")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_footer, null, {
                default: _withCtx(() => [
                  (!_ctx.isPWA)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        detail: "",
                        color: "success",
                        onClick: _ctx.showInstallPossibleModal
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.icons.downloadOutline,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("pwa.install-app")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.icons.earth,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("menu.language")) + ":", 1)
                        ]),
                        _: 1
                      }),
                      _withDirectives((_openBlock(), _createBlock(_component_ion_select, {
                        value: _ctx.locale,
                        onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx._setLocale($event.target.value)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: `locale-${locale}`,
                              value: locale
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(locale.toUpperCase()), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value"])), [
                        [_directive_i18n]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}