import store from "@/store";
import { logout } from "@/api/logout";
import seedgreenAPI from "@/lib/seedgreen-api";

// See if the auth token is still valid
function verifyToken() {
	return new Promise<void>((resolve, reject) => {
		seedgreenAPI
			.get("WebApiAuthentication/VerifyToken")
			.then(() => {
				resolve();
			})
			.catch(() => {
				reject();
			});
	});
}

// Get a new auth token
function getToken(token: string | null) {
	return new Promise((resolve, reject) => {
		seedgreenAPI
			.get("WebApiAuthentication/GetToken", {
				data: {
					token: token,
				},
			})
			.then((response) => response.data)
			.then((token) => {
				resolve(token);
			})
			.catch((e) => {
				reject(e);
			});
	});
}

// Does a token error warrant logging out? Right now, the only instance that doesn't is if the connection is lost mid-request
interface StatusError extends Error {
	status?: number;
}
function shouldLogOut(e: unknown) {
	// ERR_CONNECTION_REFUSED
	return !e || (e as StatusError).status !== 0;
}

export function ensureValidToken() {
	return new Promise<void>((resolve, reject) => {
		// Is our token valid?
		verifyToken()
			// Token is valid
			.then(() => resolve())
			// Token is not valid -- get a new one!
			.catch((e) => {
				getToken(store.state.token)
					// We were able to get a new token
					.then((token) => {
						if (token) {
							store.dispatch("setToken", token);
							resolve();
						}
						// The token was null -- logout, as there's no other way of currently addressing this
						else {
							reject(e);
							logout();
						}
					})
					// We weren't able to get a token
					.catch((e) => {
						reject(e);

						if (shouldLogOut(e)) logout();
					});
			});
	});
}
