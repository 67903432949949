import store from "@/store";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
const SECOND_IN_MILLISECONDS = 1000;

const SYNC_WINDOW_HOURS = 1; // How long to go before resyncing
const SYNC_CHECK_SECONDS = 1; // How often to check if we should resync

let syncInterval: NodeJS.Timeout | undefined;
let activeSyncPromise: Promise<unknown> | undefined;

/**
 * Make an HTTP GET request for the data from SeedGreen
 * and pass the response to the callback function.
 *
 * @param {any} path the path to the API endpoint
 * @param {any} callback the name of the function to call in store.js
 */
async function httpGet(path: string, callback: string) {
	const defaultOptions = {
		headers: { Authorization: "Bearer " + store.state.token },
	};
	const response = await seedgreenAPI.get(path, defaultOptions);
	return store.dispatch(callback, response.data);
}

// Keeps resync from getting repeatedly called on itself (only 1 instance at a time)
export async function resync() {
	activeSyncPromise = activeSyncPromise || _resync();
	return activeSyncPromise;
}

async function _resync() {
	try {
		if (!store.state.online) return undefined;
		if (!store.state.loggedIn) return undefined;
		await ensureValidToken();

		// in general, get data from the last +/- ~4 months
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 365);
		const startDateStr = startDate.toISOString().split("T")[0];

		const endDate = new Date();
		endDate.setDate(endDate.getDate() + 365);
		const endDateStr = endDate.toISOString().split("T")[0];

		await Promise.all([
			httpGet("WebApiAuthentication/GetData", "resync"),
			httpGet(`IrrigationBlocks?startDate=${startDateStr}&endDate=${endDateStr}`, "setIrrigationBlocks"),
			httpGet("Ranch", "setRanches"),
			httpGet("RanchCooler", "setRanchCoolers"),
			httpGet("Season", "setSeasons"),
		]);
	} catch (error) {
		console.error("Resync error:", error);
		throw error;
	} finally {
		activeSyncPromise = undefined;
	}
}

// Resync to ensure that data does not get stale
export default {
	run() {
		syncInterval = setInterval(() => {
			if (!store.state.loggedIn) return;
			//console.log(numberFormat((new Date() - new Date(store.state.lastSync)) / (SYNC_WINDOW_HOURS * HOUR_IN_MILLISECONDS), 'P0'));
			if (
				!store.state.lastSync ||
				new Date().getMilliseconds() - new Date(store.state.lastSync).getMilliseconds() >
					SYNC_WINDOW_HOURS * HOUR_IN_MILLISECONDS
			) {
				//console.log('Resyncing...');
				// resync just returns the current sync promise if it is already running
				resync();
			}
		}, SYNC_CHECK_SECONDS * SECOND_IN_MILLISECONDS);
	},
	stop() {
		clearInterval(syncInterval);
	},
};
