import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "./token-validation";
import store from "@/store";
import { sendMessage } from "@/components/send-message";
import seedgreenAPI from "@/lib/seedgreen-api";

const bundle = {
	setLocale: function (locale: string) {
		store.dispatch("setLocale", locale);

		OfflineQueue.add("user.setLocale", locale, function duplicateMerger(queueItem) {
			if (queueItem.endpoint === "user.setLocale" && queueItem.payload == locale) return 1;
		});
	},
	_setLocale: function (payload: string) {
		return new Promise<void>((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("User/Locale?locale=" + payload)
						.then(() => {
							resolve();
						})
						.catch((error) => {
							console.error("user::setLocale: error", error);
							reject(error);
						});
				})
				.catch((e) => {
					sendMessage("Invalid credentials. Please try again...", 5000, null, "danger");
					reject(e);
				});
		});
	},
};

export default bundle;
