import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import seedgreenAPI from "@/lib/seedgreen-api";
import store from "@/store";
import { dateFormat } from "@/utils";

export async function downloadExport(date: Date) {
	const result = await seedgreenAPI({
		url: `HarvestOverview/Export?date=${dateFormat(date, "yyyy-MM-ddZ", false)}`,
		method: "GET",
		responseType: "blob",
		headers: { Authorization: "Bearer " + store.state.token },
	});

	let filename = result.headers["content-disposition"].split('filename="')[1];
	filename = filename.substring(0, filename.lastIndexOf('"'));
	return {
		filename: filename,
		data: result.data,
	};
}

export default {
	createRanchYield: function (value: { activity: never[]; tempId: string | undefined }) {
		console.debug("createRanchYield", JSON.stringify(value));
		value.activity = value.activity || [];
		value.tempId = window.URL.createObjectURL(new Blob([])).split("/").pop();
		const payload = {
			value,
			valueType: "ranchYield",
		};

		// Update local state
		store.dispatch("createRanchYield", payload);

		OfflineQueue.add("harvestOverview.createRanchYield", payload);
	},
	_createRanchYield: function (payload: { value: unknown }) {
		console.debug("_createRanchYield", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("RanchYieldStatistic", payload.value)
						.then(() => {
							console.debug("_createRanchYield.done", JSON.stringify(payload));
							store.dispatch("commitRanchYield", payload.value);
							resolve(payload.value);
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
};
