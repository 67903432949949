import OfflineQueue from "@/api/offline-queue";
import { ensureValidToken } from "@/api/token-validation";
import store from "@/store";
import { SoilSampleTask } from "seedgreen-shared/models/SoilSampleTask";
import { AxiosResponse } from "axios";
import seedgreenAPI from "@/lib/seedgreen-api";

const abortSignals: { [key: string | number]: AbortController } = {};
const abortIrrigationBlockSignals: { [key: string | number]: AbortController } = {};

const api = {
	loadFromPlanting(plantingId?: number) {
		console.debug("api.soil-sample.loadFromPlanting", plantingId);
		if (!plantingId) {
			console.debug("plantingId is null, skipping api.soil-sample.loadFromPlanting");
			return Promise.resolve();
		}

		// Cancel any pending loads of the same planting if they already exist
		if (abortSignals[plantingId]) {
			abortSignals[plantingId].abort();
		}
		abortSignals[plantingId] = new AbortController();

		const payload = {
			value: plantingId,
		};

		OfflineQueue.add("soilSample.loadFromPlanting", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (queueItem.endpoint === "soilSample.loadFromPlanting" && queueItem.payload.value === payload.value)
				return 1;
		});
		return Promise.resolve();
	},
	_loadFromPlanting(payload: { value: number }) {
		console.debug("api.soil-sample._loadFromPlanting", payload.value);
		return seedgreenAPI
			.get("/SoilSample", {
				params: {
					plantingId: payload.value,
				},
				headers: {
					Authorization: "Bearer " + store.state.token,
				},
				signal: abortSignals[payload.value]?.signal,
			})
			.then((response: AxiosResponse<SoilSampleTask[]>) => {
				response.data.forEach((task) => store.dispatch("updateSoilSampleTask", task));
			});
	},
	loadFromIrrigationBlock(id: number) {
		console.debug("api.soilSample.loadFromIrrigationBlock", id);
		if (!id) {
			console.debug("id is null, skipping api.soilSample.loadFromIrrigationBlock");
			return Promise.resolve();
		}

		// Cancel any pending loads of the same planting if they already exist
		if (abortIrrigationBlockSignals[id]) {
			abortIrrigationBlockSignals[id].abort();
		}
		abortIrrigationBlockSignals[id] = new AbortController();

		const payload = {
			value: id,
		};
		OfflineQueue.add("soilSample.loadFromIrrigationBlock", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "soilSample.loadFromIrrigationBlock" &&
				queueItem.payload.value === payload.value
			)
				return 1;
		});
		return Promise.resolve();
	},
	_loadFromIrrigationBlock(payload: { value: number }) {
		console.debug("api.soilSample._loadFromIrrigationBlock", payload.value);
		return seedgreenAPI
			.get("/SoilSample", {
				params: {
					irrigationBlockId: payload.value,
				},
				headers: {
					Authorization: "Bearer " + store.state.token,
				},
				signal: abortSignals[payload.value]?.signal,
			})
			.then((response: AxiosResponse<SoilSampleTask[]>) => {
				response.data.forEach((task) => store.dispatch("updateSoilSampleTask", task));
			});
	},
	create: function (value: SoilSampleTask) {
		console.debug("api.soil-sample.create", JSON.stringify(value));
		value.virtualId = window.URL.createObjectURL(new Blob([])).split("/").pop();

		OfflineQueue.add("soilSample.create", value);

		// Update local state
		return store.dispatch("createSoilSampleTask", value);
	},
	_create: function (payload: SoilSampleTask) {
		console.debug("api.soil-sample._create", JSON.stringify(payload));
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.post("SoilSample", payload)
						.then((response) => response.data)
						.then((updated) => {
							updated.virtualId = payload.virtualId;
							store
								.dispatch("commitSoilSampleTask", updated)
								.then(() => {
									// rather than do both, update the irrigation block and only update the planting if we have one
									// because some tasks were migrated from planting-based blocks to irrigation blocks
									if (updated.location?.irrigationBlockId) {
										return api.loadFromIrrigationBlock(updated.location?.irrigationBlockId);
									}
									if (updated.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(updated.location.plantingIds[0]);
									}
								})
								.then(() => resolve(updated));
						})
						.catch(reject);
				})
				.catch(reject);
		});
	},
	update: function (value: SoilSampleTask) {
		console.debug("api.soil-sample.update", JSON.stringify(value));

		// Enqueue API-related mutations
		OfflineQueue.add("soilSample.update", value, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "soilSample.update" &&
				(queueItem.payload.id === value.id || queueItem.payload.virtualId == value.virtualId)
			)
				return 1;
		});

		// Update local state
		return store.dispatch("updateSoilSampleTask", value);
	},
	_update: function (payload: SoilSampleTask) {
		console.debug("api.soil-sample._update", JSON.stringify(payload));

		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.put("SoilSample", payload)
						.then((response) => response.data)
						.then((data) => {
							console.debug("api.soil-sample._update.done", JSON.stringify(data));
							payload.id = data.id;
							store
								.dispatch("updateSoilSampleTask", payload)
								.then(() => {
									// rather than do both, update the irrigation block and only update the planting if we have one
									// because some tasks were migrated from planting-based blocks to irrigation blocks
									if (data.location?.irrigationBlockId) {
										return api.loadFromIrrigationBlock(data.location?.irrigationBlockId);
									}
									if (data.location?.plantingIds?.[0]) {
										return api.loadFromPlanting(data.location.plantingIds[0]);
									}
								})
								.then(() => resolve(payload));
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	delete: function (value: SoilSampleTask) {
		const payload = value;

		// Enqueue API-related mutations
		OfflineQueue.add("soilSample.delete", payload, function duplicateMerger(queueItem) {
			// Unqueue actions that are rendered obsolete by the new action
			if (
				queueItem.endpoint === "soilSample.delete" &&
				(queueItem.payload.id === payload.id || queueItem.payload.virtualId == payload.virtualId)
			)
				return 1;
		});

		// Update local state
		return store.dispatch("deleteSoilSampleTask", value);
	},
	_delete: function (payload: SoilSampleTask) {
		return new Promise((resolve, reject) => {
			ensureValidToken()
				.then(() => {
					seedgreenAPI
						.delete("SoilSample", { data: payload })
						.then((response) => response.data)
						.then((data) => {
							resolve(data);
							if (payload.location?.irrigationBlockId)
								api.loadFromIrrigationBlock(payload.location?.irrigationBlockId);
							if (payload.location?.plantingIds?.[0])
								api.loadFromPlanting(payload.location.plantingIds[0]);
						})
						.catch((e) => {
							reject(e);
						});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
};
export default api;
