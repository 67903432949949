import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import store from "./store";
import Planting from "@/views/Planting.vue";
import { NavigationGuardWithThis, RouteRecordRaw } from "vue-router";
import { isPWA } from "./lib/pwa";
import { useAppState } from "./stores/appState";
import { loadingController } from "@ionic/vue";
import { i18n } from "./main";

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "root",
			redirect: () => {
				console.debug("started at route: /");

				// Redirect user to last "homeable" route if they have one set
				const appState = useAppState();
				if (
					appState.homeRoute &&
					appState.homeRoute != router.currentRoute.value.fullPath &&
					// If a route is removed, don't come back to it after logging in
					router
						.getRoutes()
						.map((r) => r.path)
						.includes(appState.homeRoute)
				) {
					console.debug("redirecting to homeRoute: " + appState.homeRoute);
					return appState.homeRoute;
				}

				if (store.state.permissions["view_mobile_lots"]) {
					console.debug("redirecting to lot-search");
					return { name: "lot-search" };
				}

				if (store.state.permissions["view_mobile_harvest"]) {
					console.debug("redirecting to harvest-schedule");
					return { name: "harvest-schedule" };
				}

				if (store.state.permissions["view_irrigation_schedules"]) {
					console.debug("redirecting to irrigation");
					return { name: "irrigation" };
				}

				if (store.state.permissions["set_irrigation_schedules"]) {
					console.debug("redirecting to irrigation");
					return { name: "irrigation" };
				}

				if (store.state.tasks.length) {
					console.debug("redirecting to tasks");
					return { name: "tasks" };
				}

				if (store.state.permissions["view_mobile_harvest_overview"]) {
					console.debug("redirecting to harvest-overview");
					return { name: "harvest-overview" };
				}

				console.debug("could not find a route to redirect to, redirecting to login");
				return { name: "login" };
			},
		},
		{
			path: "/harvest_overview",
			name: "harvest-overview",
			meta: {
				homeable: true,
			},
			component: () => import("@/views/HarvestOverview.vue"),
		},
		{
			path: "/harvest_overview/ranch_yield/:coolerId/:coolerName/:ranchId/:ranchName/:seasonCode/:organicStatusId/:date/:timeframe",
			name: "harvest-overview.ranch-yield.edit",
			component: () => import("@/views/HarvestOverview/HarvestOverviewRanchEdit.vue"),
		},
		{
			path: "/harvest_overview/acres/:ranchId/:ranchName/:seasonCode/:organicStatusId/:date/:variety/:cropId",
			name: "harvest-overview.acres.edit",
			component: () => import("@/views/HarvestOverview/HarvestOverviewAcresEdit.vue"),
		},
		{
			path: "/schedule",
			name: "harvest-schedule",
			meta: {
				homeable: true,
			},
			component: () => import("@/views/HarvestSchedule.vue"),
		},
		{
			path: "/search",
			name: "lot-search",
			meta: {
				homeable: true,
			},
			component: () => import("@/views/LotSearch.vue"),
			children: [
				{
					path: "/search/:plantingId",
					name: "lot-search.planting",
				} as RouteRecordRaw,
			],
			props: true,
		},
		{
			path: "/alerts",
			name: "alerts",
			meta: {
				homeable: true,
			},
			component: () => import("@/views/Alerts.vue"),
		},
		{
			path: "/irrigation",
			name: "irrigation",
			meta: {
				homeable: true,
			},
			component: () => import("@/views/Irrigation.vue"),
		},
		{
			path: "/irrigation/create",
			name: "irrigation.create",
			component: () => import("@/views/Irrigation/IrrigationEdit.vue"),
		},
		{
			path: "/irrigation/:irrigationId/edit",
			name: "irrigation.edit",
			component: () => import("@/views/Irrigation/IrrigationEdit.vue"),
		},
		{
			path: "/soil-sample/:taskId/edit",
			name: "soilSample.edit",
			component: () => import("@/views/SoilSample/SoilSampleEdit.vue"),
		},
		{
			path: "/chemical/:taskId/edit",
			name: "chemical.edit",
			component: () => import("@/views/Chemical/ChemicalEdit.vue"),
		},
		{
			path: "/ticket/entry/:plantingId",
			name: "plantingTicket.entry",
			component: () => import("@/views/Ticket/EnterPlantingTicket.vue"),
		},
		{
			path: "/ticket/view/:plantingId/:ticketId",
			name: "plantingTicket.view",
			component: () => import("@/views/Ticket/EnterPlantingTicket.vue"),
		},
		{
			path: "/plantings/:plantingId",
			name: "planting",
			component: Planting,
			children: [
				{
					name: "planting.home",
					path: "",
					redirect: "details",
				},
				{
					path: "details",
					name: "planting.details",
					component: () => import("@/views/Planting/PlantingDetails.vue"),
					props: true,
				},
				{
					path: "map/:noteId?",
					name: "planting.map",
					component: () => import("@/views/Planting/PlantingMap.vue"),
					props: true,
				},
				{
					path: "notes",
					name: "planting.notes",
					component: () => import("@/views/Planting/PlantingNotes.vue"),
					props: true,
				},
				{
					path: "notes/:noteId",
					name: "planting.notes.note",
					component: () => import("@/views/Planting/PlantingNotes.vue"),
					props: true,
				},
				{
					path: "alerts",
					name: "planting.alerts",
					component: () => import("@/views/Planting/PlantingAlerts.vue"),
					props: true,
				},
				{
					path: "tasks",
					name: "planting.tasks",
					component: () => import("@/views/Task/TasksList.vue"),
					props: true,
				},
				{
					path: "task-requests",
					name: "planting.taskRequests",
					component: () => import("@/views/Task/TaskRequestsList.vue"),
					props: true,
				},
			],
		},
		{
			path: "/tasks/",
			component: () => import("@/views/Tasks.vue"),
			children: [
				{
					path: "",
					redirect: "list",
				},
				{
					path: "list",
					name: "tasks.list",
					component: () => import("@/views/Task/TasksList.vue"),
					meta: {
						homeable: true,
					},
				},
				{
					path: "requests",
					name: "tasks.requests",
					component: () => import("@/views/Task/TaskRequestsList.vue"),
				},
			],
		},
		{
			path: "/user-agreement",
			name: "user-agreement",
			component: () => import("@/views/UserAgreement.vue"),
		},
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/Login.vue"),
		},
		{
			path: "/seedgreen-move-notice",
			name: "seedgreen-move-notice",
			component: () => import("@/views/SeedgreenHasMoved.vue"),
			meta: {
				pwaOnly: true,
			},
		},
	],
});

let stateRestored = false;
const beforeEach: NavigationGuardWithThis<undefined> = async (to, from, next) => {
	//Block navigation until data comes back from IndexedDB or WebSQL through localforage.
	//Should only happen once at the beginning of a session.
	if (!stateRestored) {
		const loading = await loadingController.create({
			message: i18n.global.t("common.restoring-data-dot"),
		});
		await loading.present();

		try {
			// @ts-expect-error vuex-persist doesn't properly type the store
			await store.restored;
			stateRestored = true;
		} finally {
			loadingController.dismiss();
		}
	}

	if (to.meta.pwaOnly && !isPWA) {
		next({ name: "root" });
	}

	if (to.meta.homeable) {
		const appState = useAppState();
		console.debug("updating home route: ", to.fullPath);
		appState.homeRoute = to.fullPath;
	}

	next();
};
router.beforeEach(beforeEach);

export default router;
