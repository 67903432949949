import router from "@/router";
import store from "@/store";
import memory from "@/memory";
import { useAppState } from "@/stores/appState";

export function logout() {
	memory.clear();

	useAppState().$reset();

	store.dispatch("logout").then(() => router.push({ name: "login" }));
}
